import _defineProperty from "/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { isPreview } from '@webapp/common/lib/const';
import { LocalStorage } from '@webapp/common/lib/storage';
export var loadSavedAnswers = function loadSavedAnswers(domain, questions, rawSavedAnswers) {
  var savedAnswers = rawSavedAnswers || [];

  try {
    if (savedAnswers.length === 0) {
      var parsedSavedQuestions = LocalStorage.get("new_answers__".concat(domain));

      if (Array.isArray(parsedSavedQuestions)) {
        savedAnswers = parsedSavedQuestions;
      }
    }
  } catch (e) {}

  if (isPreview) {
    savedAnswers = [];
  }

  return questions.map(function (q) {
    var savedAnswer = savedAnswers.find(function (_ref) {
      var id = _ref.id;
      return q.id === id;
    });

    if (savedAnswer) {
      return _objectSpread(_objectSpread({}, q), {}, {
        savedAnswer: savedAnswer
      });
    }

    return q;
  });
};
export var prepareResidences = function prepareResidences(rows, parent) {
  return rows.map(function (_ref2) {
    var id = _ref2.ID,
        level = _ref2.LEVEL,
        name = _ref2.NAME;
    return {
      id: id,
      name: name,
      level: parseInt(level, 10),
      parent: parent
    };
  });
};